<template>
    <vue-plotly style="width:100%;" :data="data" :layout="layout" :config="config" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'HorizontalBarChart',
  components: { VuePlotly },
  props: {
    x: Array,
    height: {
      type: Number,
      default: 400
    },
    width: {
      type: Number,
      default: 400
    },
    y: Array,
    title: String,
    ticklabels: {
      type: Boolean,
      default: true
    },
    marginleft: {
      type: Number,
      default: 120
    },
    xtitle: {
      type: String,
      default: ''
    },
    tickfont_size:{
      type: Number,
      default:12
    },
    round: {
      type: Number,
      default: 0
    },
    fontsize: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      colorbar: [],
      config: {
        displayModeBar: false
      },
      layout: {
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
          l: this.marginleft,
          r: 15,
          b: 50,
          t: 30,
          pad: 4},
        title: {
          text:this.title,
          font: {
            size:15,
            family: 'Calibri',
          },
        xref: 'paper',
        x: 0.00,
        },
        height: this.height,
        width: this.width,
        yaxis: {
          automargin: true,
          title:{
            standoff:20,
          },
          showticklabels: this.ticklabels,
          tickfont:{
            size:this.tickfont_size
          },
        // anchor: 'free',
        // side: 'right',
        // position:-10
          },
        xaxis: {
          automargin: true,
          tickformat:',.1%',
          title: {
            standoff:20,
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 12
            },
          }
        }
      },
      options:{
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio:false
      }
    }
  },
  watch: {
    '$store.state.selectedRegion': function () {
      this.getColors()
    },
    x () {
      this.getColors()
    }
  },
  computed: {
    data () {
      return [
      { type: 'bar',
        x: this.x,
        y:this.y,
        orientation: 'h',
        text:this.x.map(d=>(d*100).toFixed(this.round)),
        textposition: 'auto',
        hoverinfo: 'none',
        marker: {
          color: this.colorbar
          }
        }
      ]
    }
  },
  mounted () {
    this.getColors()
  },
  methods: {
    getColors () {
      var colorArray = [];
      for (var i = 0; i<this.y.length; i++) {
        if (this.y[i] === this.$store.state.selectedRegion) {
          colorArray.push('rgba(20,66,115,1.00))')
        }
        else {
          colorArray.push('rgba(180,180,180,1.00)')
        }
      }
      this.colorbar=colorArray
    }
  }
}

</script>

<style>


</style>
